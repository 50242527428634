import invariant from 'invariant';

import { Box } from 'components/Box';
import { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsAnchorBlock } from 'modules/cms/blocks/Anchor/CmsAnchorBlock';
import { cmsBlockMappingByType } from 'modules/cms/blocks/cmsBlockMappingByType';
import {
  getCmsBlockHasBackground,
  getIsContentFullWidthLayout,
} from 'modules/cms/helpers';
import { getCmsBlockMustNotHaveDivider } from 'modules/cms/helpers/getCmsBlockMustNotHaveDivider';

import { CmsMappedBlock } from './CmsMappedBlock';

type Props<TBlock extends CmsApiBlock> = {
  content: CmsApiPage;
  isBody: boolean;
  block: TBlock;
  isPageHeader: boolean;
  anchor: CmsApiBlock | undefined;
  offsetAnchors: boolean;
  index: number;
};

export function mapCmsBlock<TBlock extends CmsApiBlock>({
  content,
  isBody,
  block,
  isPageHeader,
  anchor,
  offsetAnchors,
  index,
}: Props<TBlock>): CmsMappedBlock<TBlock> {
  const blockMapping = cmsBlockMappingByType[block.type];
  if (!blockMapping) throw new Error(`Expected block type, got ${block.type}`);

  const { Component, width } = blockMapping;

  blockMapping.invariant.forEach((item) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (block.data[item.prop]) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      invariant(block.data[item.prop], item.msg);
    }
  });

  // @ts-expect-error will be fixed later
  const widthValue = typeof width === 'function' ? width(block.data) : width;

  const fullWidthLayout = getIsContentFullWidthLayout({ content });
  const blockHasBackground = getCmsBlockHasBackground({
    block,
    fullWidthLayout,
  });

  const mustNotHaveDivider = getCmsBlockMustNotHaveDivider({ block });

  return {
    type: block.type,
    block,
    id: block.id,
    width: widthValue,
    mustNotHaveDivider,
    padding:
      (isBody &&
        ((block.type === 'SPLIT_CONTENT' &&
          block.data.appearance === 'FULL_WIDTH') ||
          [
            'CTA_HERO',
            'STICKY_HEADER_LINKS',
            'DONATE',
            'SIGN_UP',
            'SCROLL_TRACKING',
          ].includes(block.type))) ||
      blockHasBackground
        ? 'NONE'
        : 'DEFAULT',
    hasBackground: blockHasBackground,
    isHidden: block.type === 'FREE_CODE' && !block.data.isVisible,
    isInline: block.type === 'SCROLL_TRACKING',
    component: (
      <>
        {anchor && anchor.type === 'ANCHOR' && (
          <Box
            position="absolute"
            top={offsetAnchors ? [0, null, null, -56] : 0}
          >
            <CmsAnchorBlock data={anchor.data} />
          </Box>
        )}
        <div>
          <Component
            content={content}
            isPageHeader={
              isPageHeader &&
              ['CTA_HERO', 'DONATE', 'SIGN_UP'].includes(block.type)
            }
            data={block.data}
            pageUpdated={content.updated}
            blockId={block.id}
            blockIndex={index}
            fullWidthLayout={fullWidthLayout}
          />
        </div>
      </>
    ),
  };
}
