import { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

type Props = {
  content: CmsApiPage;
};

export function getContentHasBodyHeader({ content }: Props) {
  const firstBlock: CmsApiBlock | undefined = content.contentBlocks[0];

  const firstBlockSkipsTitle =
    content.contentBlocks &&
    content.contentBlocks.length > 0 &&
    (['CTA_HERO', 'DONATE'].includes(firstBlock.type) ||
      (firstBlock.type === 'SPLIT_CONTENT' &&
        firstBlock.data.appearance === 'FULL_WIDTH'));

  return !(firstBlockSkipsTitle || content.heroSearch || !content.showTitle);
}
